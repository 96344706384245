import { forwardRef, useImperativeHandle, useRef } from "react";

interface IVideoCapture {
    videoSrc: string;
    onVideoPlay?: () => void;
    onCaptureSnapshot: (snapshot: HTMLCanvasElement) => void;
}

// Define the type for the ref that includes the `shoot` method
export interface VideoCaptureRef {
    shoot: () => void;
}

const VideoCapture = forwardRef<VideoCaptureRef, IVideoCapture>(({
    onCaptureSnapshot,
    onVideoPlay,
    videoSrc
}, ref) => {
    const videoRef = useRef<HTMLVideoElement | null>(null);
    const outputRef = useRef<HTMLDivElement | null>(null);
    const scaleFactor = 0.25;
    const snapshots: HTMLCanvasElement[] = [];

    const capture = (video: HTMLVideoElement, scaleFactor: number): HTMLCanvasElement => {
        if (!video) return document.createElement('canvas');

        const w = video.videoWidth * scaleFactor;
        const h = video.videoHeight * scaleFactor;
        const canvas = document.createElement('canvas');
        canvas.width = w;
        canvas.height = h;

        const ctx = canvas.getContext('2d');
        if (ctx) {
            ctx.drawImage(video, 0, 0, w, h);
        }
        return canvas;
    };

    /**
     * Invokes the capture function and attaches the canvas element to the DOM.
     */
    const shoot = () => {
        if (videoRef.current) {
            const canvas = capture(videoRef.current, scaleFactor);
            // const img = new Image();
            // img.src = canvas.toDataURL('image/png');
            canvas.onclick = () => {
                window.open(canvas.toDataURL('image/jpg'));
            };

            snapshots.unshift(canvas); // Add the new canvas to the beginning of the snapshots array
            onCaptureSnapshot(canvas);

            if (outputRef.current) {
                outputRef.current.innerHTML = ''; // Clear the previous output
                // Append the last 4 snapshots to the output
                for (let i = 0; i < Math.min(4, snapshots.length); i++) {
                    outputRef.current.appendChild(snapshots[i]);
                }
            }
        }
    };

    // Expose the shoot function to the parent component
    useImperativeHandle(ref, () => ({
        shoot,
    }));

    return (
        <>
        {/* <div className="wrap"> */}
            <video
                ref={videoRef}
                // width={320}
                autoPlay
                // controls
                muted
                loop
                style={{ width: '100%', height: '100%' }}
                onPlay={onVideoPlay}
            >
                <source src={videoSrc} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            {/* <br />
            <button id="cit" onClick={shoot} className="button">Capture</button>
            <br /> */}
            {/* <div id="output" ref={outputRef}></div> */}
        {/* </div> */}
        </>
    );
});

export default VideoCapture;